import { render, staticRenderFns } from "./splitSubject.vue?vue&type=template&id=df214dd6&scoped=true&"
import script from "./splitSubject.vue?vue&type=script&lang=js&"
export * from "./splitSubject.vue?vue&type=script&lang=js&"
import style0 from "./splitSubject.vue?vue&type=style&index=0&id=df214dd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df214dd6",
  null
  
)

export default component.exports