<template>
  <div v-loading="loading" class="splitSubject">
    <div class="card inline">
      <detailsHeader :show-popver="false" />
    </div>
    <div class="card">
      <div class="card-title">{{ $route.query.name }}-拆分科目</div>
      <div class="subject">
        选择拆分后的科目：<el-select
          v-model="form.subjectId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="questionsBox">
        <div v-for="(item, index) in listData" :key="index" class="questions">
          <div class="tag">
            <span v-if="item.type == 0">客观题</span>
            <span v-if="item.type == 1">主观题</span>
            ({{ item.total }}分)
            <el-button
              type="text"
              style="margin-left: 10px"
              @click="allCheck(item)"
              >全选</el-button
            >
            <el-button type="text" @click="filterCheck(item)">反选</el-button>
          </div>
          <div class="check">
            <el-checkbox-group v-model="item.checkList">
              <el-checkbox
                v-for="(it, ind) in item.children"
                :key="ind"
                :style="{ color: it.hasSplit === 1 ? '#ddd' : '' }"
                :label="it"
                >{{ it.order }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" :loading="btnLoading" @click="submit"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import {
  getquestionlist,
  createsubjectsplit,
  getexistsubjectlist,
  getsubjectsplitlist,
} from "@/core/api/academic/statistics";
import { getcansplitsubjectlist } from "@/core/api/academic/report";
import { getSubjectList } from "@/core/util/util";

import axios from "axios";
export default {
  name: "SplitSubject",
  components: {
    detailsHeader,
  },
  data() {
    return {
      subjectOptions: [],
      listData: [],
      form: {},
      btnLoading: false,
      loading: true,
      options: [],
    };
  },
  mounted() {
    this.getData();
    this.getDifferencelist();
    const { statId, id, score } = this.$route.query;
    this.form.statId = statId;
    this.form.splitFromId = id;
    this.form.score = score;
    this.getSubjectList();
  },
  methods: {
    allCheck(item) {
      this.$set(
        item,
        "checkList",
        item.children.filter((item) => item.hasSplit !== 1)
      );
    },
    filterCheck(item) {
      let checkedArr = item.checkList.map((item) => item.order);
      let hasNotSplits = item.children.filter((item) => item.hasSplit !== 1);
      let filters = hasNotSplits.filter((it) => !checkedArr.includes(it.order));
      this.$set(item, "checkList", filters);
    },
    async getSubjectList() {
      this.subjectOptions = getSubjectList(this.$route.query.level);
      const res = await getcansplitsubjectlist({
        statId: this.$route.query.statId,
      });
      this.subjectOptions = res.data.data;
    },
    async getData() {
      const res = await getquestionlist({ id: this.$route.query.id });
      const { data: list } = res.data;
      let typeArr = Array.from(new Set(list.map((item) => item.type)));
      this.listData = typeArr.map((item) => {
        return {
          type: item,
          checkList: [],
          children: list.filter((it) => it.type === item),
          total: list
            .filter((it) => it.type === item)
            .reduce((prev, it) => {
              if (it.type === item) {
                return prev + it.score;
              }
            }, 0),
        };
      });
      this.loading = false;
    },
    getDifferencelist() {
      const { statId } = this.$route.query;
      axios
        .all([
          getexistsubjectlist({ statId: statId }),
          getsubjectsplitlist({ statId: statId }),
        ])
        .then((res) => {
          const [existData, subjectList] = [
            res[0].data ? res[0].data.data : [],
            res[1].data ? res[1].data.data : [],
          ];
          const existIds = existData.map((item) => item.id);
          subjectList.map((item) => {
            if (!existIds.includes(item.id)) {
              this.options.push(item);
            }
          });
        });
    },
    async submit() {
      let arr = this.listData.map((item) => item.checkList);
      let checked = arr.flat();
      if (checked.length) {
        if (this.form.subjectId) {
          this.form.questions = checked.map((item) => {
            return {
              questionOrder: item.orderSave,
              questionScore: item.score,
            };
          });
          try {
            this.btnLoading = true;
            await createsubjectsplit(this.form);
            this.btnLoading = false;
            this.$message.success("保存成功");
            this.$router.go(-1);
          } catch {
            this.btnLoading = false;
          }
        } else {
          this.$message({
            showClose: true,
            message: "请选择科目",
            type: "error",
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请选择题目",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.splitSubject {
  .card-title {
    font-size: 16px;
    text-align: center;
    color: #080a09;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .subject {
    margin-bottom: 30px;
    font-size: 14px;
  }
  .questionsBox {
    margin-bottom: 310px;
    .questions {
      .tag {
        box-sizing: border-box;
        padding: 19px 18px;
        color: #080a09;
        background: #f2f5f8;
        font-style: 14px;
        font-weight: 600;
        border: 1px solid #d8d8d8;
      }
      .check {
        margin: 30px 0;
      }
    }
  }
  .btn {
    text-align: center;
  }
}
</style>
